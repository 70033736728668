'use strict';

angular.module('informaApp')
    .directive('infRadioButton', function () {
        return {
            restrict: 'E',
            scope: {
                label: '@',
                model: '=',
                radioValue: '=',
                radioName: '@',
                size: '@',
                blocked: '='
            },
            templateUrl: 'directives/inf-radio-button/template.ptl.html',
            link: function (scope, el, attrs, controllers) {
                scope.onClick = function () {
                    if (scope.blocked || scope.model === scope.radioValue) {
                        return;
                    }

                    scope.model = scope.radioValue;
                }
            }
        }
    });
